<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2022-02-25 11:55:00
-->
<template>
	<div class="setUp">
		<div class="setUp_tab">
			<module-list :moduleList="moduleList" :currentTab="currentTab" @changeTab="changeTab" />
		</div>
		<div class="setUp_content">
			<!-- 账号管理 -->
			<account-table v-if="currentTab == '账号管理'"/>
			<!-- 角色管理 -->
			<role-table v-if="currentTab == '角色管理'"/>
			<!-- 操作日志 -->
			<log-table v-if="currentTab == '操作日志'"/>
			<!-- 发送通知 -->
			<inform-table v-if="currentTab == '发送通知'"/>
		</div>
	</div>
</template>
<script>
	import moduleList from "@/components/moduleList/index.vue";
	import accountTable from "./components/accountTable.vue";
	import roleTable from "./components/roleTable.vue";
	import logTable from "./components/logTable.vue";
	import informTable from "./components/informTable.vue";
	import commonFn from "@/utils/common.js";
	import storage from 'store';
	export default {
		components: {
			moduleList,
			accountTable,
			roleTable,
			logTable,
			informTable
		},
		data() {
			return {
				currentTab: "",
				moduleList: [{
						label: "账号管理",
						value: 1
					},
					{
						label: "角色管理",
						value: 2
					},
					{
						label: "操作日志",
						value: 3
					},
					{
						label: "发送通知",
						value: 4
					},
				],
			}
		},
		destroyed() {
			// console.log("beforeDestroy")
			storage.remove('currentTab')
		},
		created() {
			console.log("设置路由>>>>", this.$route)
			this.moduleList = []
			if(this.$route.meta.secondaryMenu) {
				let secondaryMenu = this.$route.meta.secondaryMenu
				this.moduleList = commonFn.secondaryMenu(secondaryMenu).filter(item => item.label != '操作日志')
				// this.currentTab = this.moduleList[0].label
				if (storage.get('currentTab')) {
					this.currentTab = storage.get('currentTab')
				} else {
					this.currentTab = this.moduleList[0].label
				}
			}
		},
		methods: {
			changeTab(props) {
				this.currentTab = props.label;
				storage.set('currentTab', this.currentTab, 7 * 24 * 60 * 60 * 1000)
			},
		},
	}
</script>
<style lang="scss" scoped>
	@import "../../styles/index.scss";

	.flex_between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.setUp {
		display: flex;
		flex-direction: column;

		.setUp_tab {
			padding-left: 1236px;
			background-color: #F7F7F7;
			margin-top: 4px;
		}

		.setUp_content {
			background-color: #ffffff;
			padding: 20px 40px;
			display: flex;
			flex-direction: column;
		}
	}
</style>
