<template>
	<div class="informTable">
		<div @click="handleForm('add')" class="setUp_add_btn">
			+发送通知
		</div>
		<div class="setUp_table">
			<div class="setUp_table_box">
				<el-table class="setUp_table_inner" :header-cell-style="headStyle" :cell-style="rowStyle"
					:data="tableData" :border="false">
					<el-table-column width="80px" type="index" label="序号">
					</el-table-column>
					<el-table-column prop="avator" label="标题">
						<template slot-scope="scope">
							<div class="setUp_table_cell">
								{{scope.row.title}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="avator" label="内容">
						<template slot-scope="scope">
							<div class="setUp_table_cell">
								{{scope.row.detail}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="avator" label="附件">
						<template slot-scope="scope">
							<div v-if="scope.row.params&&scope.row.params.attach&&scope.row.senderType =='SYSTEM'"
								@click="downLoadFile(scope.row.fileType,scope.row.fileName, scope.row.params.attach)" class="setUp_table_action">
								<span><i style="margin-right: 4px;"
											class="el-icon-paperclip"></i>{{scope.row.fileName?scope.row.fileName:"下载"}}</span>
							</div>
							<div v-else class="setUp_table_action">
								<span>--</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="createTime" label="发送时间">
					</el-table-column>
				</el-table>
			</div>
			<div class="setUp_table_page">
				<el-pagination @current-change="currentChange" :current-page.sync="pageIndex" :page-size="pageSize"
					layout="total, prev, pager, next" :total="totalCount">
				</el-pagination>
			</div>
		</div>
		<div class="setUp_dialog">
			<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeLogDialog" width="579px" :title="diaLogLogTitle" :visible.sync="diaLogLogVisible">
				<div class="handle_form">
					<el-form ref="logDetail" :rules="logRules" :model="logDetail" label-width="70px">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="title" class="flex_item" label="通知标题:">
									<input v-model="logDetail.title" placeholder="请输入通知标题"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="detail" class="" label="通知内容:">
									<textarea v-model="logDetail.detail" placeholder="请输入通知内容"
										class="handle_form_textarea"></textarea>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="" label="附件:">
									<uploadFile @successUpload="successUpload" :fileSrc="logDetail.attPath" />
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="handle_form_btn">
						<span @click="closeLogDialog" class="handle_form_cancel">取消</span>
						<span v-preventReClick @click="submitDialog" class="handle_form_confirm">确定</span>
					</div>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		addInform,
		listInform
	} from "@/api/setUp";
	import uploadFile from "@/components/uploadFile/index.vue";
	import {
		saveAs
	} from 'file-saver';
	export default {
		components: {
			uploadFile
		},
		data() {
			return {
				pageIndex: 1,
				pageSize: 10,
				totalCount: 0,
				tableData: [],
				diaLogLogTitle: "",
				diaLogLogVisible: false,
				logRules: {
					title: [{
						required: true,
						message: "请输入通知标题",
						trigger: "blur"
					}],
					detail: [{
						required: true,
						message: "请输入通知内容",
						trigger: "blur"
					}]
				},
				logDetail: {
					title: "",
					attPath: "",
					detail: ""
				}
			};
		},
		created() {
			this.getInformList();
		},
		methods: {
			headStyle() {
				return "text-align:center;background: #F7F7F7;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;";
			},
			rowStyle() {
				return "text-align:center;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #333333;";
			},
			closeLogDialog() {
				this.diaLogLogVisible = false;
			},
			submitDialog() {
				this.$refs["logDetail"].validate(valid => {
					if (valid) {
						let logDetail = JSON.parse(JSON.stringify(this.logDetail));
						this.$set(logDetail, "params", {
							attach: logDetail.attPath
						});
						addInform(logDetail).then(res => {
							if (res.code == 0) {
								this.diaLogLogVisible = false;
								this.$message({
									message: "通知发送成功~",
									type: "success"
								});
								this.pageIndex = 1;
								this.getInformList();
							}
						});
					}
				});
			},
			closeMessege() {
				this.messegeVisible = false;
			},
			successUpload(data) {
				this.logDetail.attPath = data.fileData;
			},
			getCharFromUtf8(str) {
				var cstr = "";
				var nOffset = 0;
				if (str == "")
					return "";
				str = str.toLowerCase();
				nOffset = str.indexOf("%e");
				if (nOffset == -1)
					return str;
				while (nOffset != -1) {
					cstr += str.substr(0, nOffset);
					str = str.substr(nOffset, str.length - nOffset);
					if (str == "" || str.length < 9)
						return cstr;
					cstr += this.utf8ToChar(str.substr(0, 9));
					str = str.substr(9, str.length - 9);
					nOffset = str.indexOf("%e");
				}
				return cstr + str;
			},
			utf8ToChar(str) {
				var iCode, iCode1, iCode2;
				iCode = parseInt("0x" + str.substr(1, 2));
				iCode1 = parseInt("0x" + str.substr(4, 2));
				iCode2 = parseInt("0x" + str.substr(7, 2));
				return String.fromCharCode(((iCode & 0x0F) << 12) | ((iCode1 & 0x3F) << 6) | (iCode2 & 0x3F));
			},
			// 获取通知列表
			getInformList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				};
				listInform(params).then(res => {
					if (res.data && res.data.length > 0) {
						res.data.forEach(item => {
							if (item.params && item.params.attach) {
								let pointIndex = item.params.attach.lastIndexOf('.')
								let lineIndex = item.params.attach.lastIndexOf('/')
								let fileName = ""
								let fileType = ""
								if (lineIndex != -1) {
									fileName = item.params.attach.substring(lineIndex + 1, item.params
											.attach.length)
										.toLowerCase()
									fileName = this.getCharFromUtf8(fileName)
									fileType = item.params.attach.substring(pointIndex + 1, item.params.attach.length).toLowerCase()
								}
								item.fileName = fileName
								item.fileType = fileType
							}
						})
					}
					this.tableData = res.data;
					this.totalCount = res.page.totalCount * 1;
				});
			},
			currentChange(val) {
				this.pageIndex = val;
				this.getInformList();
			},
			downLoadFile(type, name, url) {
				if (type == 'txt') {
					let xhr = new XMLHttpRequest();
					xhr.open("get", url, true);
					xhr.responseType = "blob";
					xhr.onload = function() {
						if (this.status == 200) {
							const reader = new FileReader()
							reader.onload = function() {
								// console.log('reader.result', reader.result)
								var blob = new Blob([reader.result], {
									type: 'text/plain;charset=utf-8'
								})
								saveAs(blob, name)
							}
							reader.readAsText(this.response);
						}
					};
					xhr.send();
				} else {
					window.open(url);
				}
			},
			handleForm(type) {
				switch (type) {
					case "add":
						this.logDetail = {};
						this.$nextTick(() => {
							this.$refs.logDetail.clearValidate();
						});
						this.diaLogLogTitle = "通知发送";
						this.diaLogLogVisible = true;
						break;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";
	@import "./common.scss";

	.informTable {
		display: flex;
		flex-direction: column;
	}
</style>
