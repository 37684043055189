import axios from '@/utils/request'

const Api = {
	rolesList: "/api/backyard/iam/role/list",
	permissionTree: "/api/backyard/iam/resourcePermission/list",
	rolesAdd: "/api/backyard/iam/role/",
	rolesDetail: "/api/backyard/iam/role/",
	rolesEdit: "/api/backyard/iam/role/",
	rolesDelete: "/api/backyard/iam/role/",
	accountList: "/api/backyard/iam/user/list",
	accountAdd: "/api/backyard/iam/user/",
	accountDetail: "/api/backyard/iam/user/",
	accountEdit: "/api/backyard/iam/user/",
	accountDelete: "/api/backyard/iam/user/",
	accountLog: "/api/backyard/iam/operationLog/list",
	informList: "/api/backyard/notification/list",
	informAdd: "/api/backyard/notification/announce",
}
// 获取角色列表
export function getRoles (params) {
  return axios({
    method: 'get',
    url: Api.rolesList,
    params: params
  })
}
// 获取权限树
export function getPermission (params) {
  return axios({
    method: 'get',
    url: Api.permissionTree,
    params: params
  })
}

// 角色添加
export function addRoles (params) {
  return axios({
    method: 'post',
    url: Api.rolesAdd,
    data: params
  })
}
// 获取角色详情
export function detailRoles (params) {
  return axios({
    method: 'get',
    url: Api.rolesDetail + params
  })
}
// 角色编辑
export function editRoles (id, params) {
  return axios({
    method: 'put',
    url: Api.rolesEdit + id,
    data: params
  })
}
// 角色删除
export function deleteRoles (id) {
  return axios({
    method: 'delete',
    url: Api.rolesDelete + id
  })
}
// 获取账号列表
export function getAccount (params) {
  return axios({
    method: 'get',
    url: Api.accountList,
    params: params
  })
}
// 新增账号
export function addAccount (params) {
  return axios({
    method: 'post',
    url: Api.accountAdd,
    data: params
  })
}
// 账号详情
export function detailAccount (params) {
  return axios({
    method: 'get',
    url: Api.accountDetail + params,
  })
}
// 编辑账号
export function editAccount (id, params) {
  return axios({
    method: 'put',
    url: Api.accountEdit + id,
    data: params
  })
}
// 删除账号
export function deleteAccount (id) {
  return axios({
    method: 'delete',
    url: Api.accountDelete + id,
  })
}
// 账号操作日志
export function logAccount (params) {
  return axios({
    method: 'get',
    url: Api.accountLog,
    params: params
  })
}
// 获取通知列表
export function listInform (params) {
  return axios({
    method: 'get',
    url: Api.informList,
    params: params
  })
}
// 发送通知
export function addInform (params) {
  return axios({
    method: 'post',
    url: Api.informAdd,
    data: params
  })
}