<template>
	<div class="roleTable">
		<div @click="handleForm('add')" class="setUp_add_btn">
			+添加角色
		</div>
		<div class="setUp_table">
			<div class="setUp_table_box">
				<el-table class="setUp_table_inner" :header-cell-style="headStyle" :cell-style="rowStyle"
					:data="tableData" :border="false">
					<el-table-column :index="indexFormat" width="80px" type="index" label="序号">
					</el-table-column>
					<el-table-column prop="name" label="角色名称">
					</el-table-column>
					<el-table-column prop="code" label="角色ID">
					</el-table-column>
					<el-table-column prop="description" label="角色描述">
					</el-table-column>
					<el-table-column prop="createTime" label="创建时间">
					</el-table-column>
					<el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="setUp_table_action">
								<span @click="handleForm('edit',scope)">编辑</span>
								<span @click="handleForm('delete',scope)">删除</span>
								<!-- <span @click="handleForm('disable',scope)">禁用</span> -->
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="setUp_table_page">
				<el-pagination @current-change="currentChange" :current-page.sync="pageIndex" :page-size="pageSize"
					layout="total, prev, pager, next" :total="totalCount">
				</el-pagination>
			</div>
		</div>
		<div class="setUp_dialog">
			<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false"
				@close="closeRoleDialog" width="579px" :title="diaLogRoleTitle" :visible.sync="diaLogRoleVisible">
				<div class="handle_form">
					<el-form ref="roleDetail" :rules="roleRules" :model="roleDetail" label-width="70px">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" prop="name" label="角色名称:">
									<input v-model="roleDetail.name" placeholder="请输入角色名称"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" prop="code" label="角色ID:">
									<input v-model="roleDetail.code" placeholder="请输入角色ID"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="" prop="description" label="角色描述:">
									<textarea v-model="roleDetail.description" placeholder="请输入角色描述"
										class="handle_form_textarea"></textarea>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="" prop="permissionIdList" label="拥有权限:">
									<el-tree ref="permissionTree" @check="getCheckedNodes" :data="roleTree"
										show-checkbox node-key="id" :default-checked-keys="roleDetail.permissionIdList">
									</el-tree>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="handle_form_btn">
						<span @click="closeRoleDialog" class="handle_form_cancel">取消</span>
						<span v-preventReClick @click="handleForm('submit')" class="handle_form_confirm">确定</span>
					</div>
				</div>
			</el-dialog>
		</div>
		<messege-box ref="messegeBox" @confirmMessege="confirmMessege" @closeMessege="closeMessege"
			:isOpen="messegeVisible" :messegeTip="messegeTip" :messegeContent="messegeContent"
			:messegeType="messegeType" />
	</div>
</template>

<script>
	import {
		getRoles,
		getPermission,
		addRoles,
		detailRoles,
		editRoles,
		deleteRoles
	} from "@/api/setUp";
	import commonFn from "@/utils/common.js";
	export default {
		data() {
			const checkPermissionId = (rule, value, callback) => {
				console.log("roleCheckedIds", this.roleCheckedIds)
				if (this.roleCheckedIds.length == 0) {
					callback(new Error("请选择权限"));
				} else {
					callback();
				}
				callback();
			};
			return {
				pageIndex: 1,
				pageSize: 10,
				totalCount: 0,
				tableData: [],
				diaLogRoleTitle: "",
				diaLogRoleVisible: false,
				roleRules: {
					name: [{
						required: true,
						message: '请输入角色名称',
						trigger: 'change'
					}],
					code: [{
						required: true,
						message: '请输入角色ID',
						trigger: 'change'
					}],
					description: [{
						required: true,
						message: '请输入角色描述',
						trigger: 'change'
					}],
					permissionIdList: [{
						required: true,
						validator: checkPermissionId,
						trigger: 'blur'
					}]
				},
				roleDetail: {},
				messegeVisible: false,
				messegeTip: "",
				messegeContent: "",
				messegeType: "",
				roleTree: [],
				roleCheckedIds: [],
				roleId: ""
			}
		},
		watch: {
			diaLogRoleVisible: {
				handler(val) {
					console.log(val, "val")
					if (!val) {
						this.$nextTick(() => {
							this.$refs.roleDetail.resetFields()
							this.$refs.roleDetail.clearValidate()
						})
					}
				},
				deep: true
			}
		},
		created() {
			this.rolesList()
		},
		methods: {
			headStyle() {
				return 'text-align:center;background: #F7F7F7;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;'
			},
			rowStyle() {
				return 'text-align:center;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #333333;'
			},
			// 序号处理
			indexFormat(index) {
				const page = this.pageIndex;
				const pageSize = this.pageSize;
				let resultIndex = index + 1 + (page - 1) * pageSize
				return resultIndex
			},
			// 获取角色列表
			rolesList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				}
				getRoles(params).then((res) => {
					this.tableData = res.data
					this.totalCount = res.page.totalCount * 1
				})
			},
			// 角色分页
			currentChange(val) {
				this.pageIndex = val
				this.rolesList()
			},
			// 获取权限树
			permissionTree() {
				return new Promise((resolve, reject) => {
					getPermission().then((res) => {
						if (res.code == 0) {
							let resTree = res.data
							// console.log("resTree", resTree)
							if (resTree.length > 0) {
								resTree.forEach(item => {
									if (item.resourceCode == "System") {
										console.log(item.children, "chileahjsahdk")
										item.children = item.children.filter(el => {
											return el.id !== "10097"
										})
									}
								})
							}
							this.roleTree = commonFn.setPermissionTree(resTree)
							resolve(this.roleTree)
						} else {
							reject()
						}
					})
				})
			},
			// 获取勾选权限
			getCheckedNodes(val1, val2) {
				// console.log("getCheckedNodes1", val2)
				this.roleCheckedIds = val2.checkedKeys.concat(val2.halfCheckedKeys)
				// console.log("getCheckedNodes2", this.roleCheckedIds)
			},

			// 获取角色详情
			rolesDetail(id) {
				detailRoles(id).then((res) => {
					if (res.code == 0) {
						if (res.data.permissionList && res.data.permissionList.length > 0) {
							let treeData = JSON.parse(JSON.stringify(this.roleTree))
							let permissionArr = res.data.permissionList.map(itemOne => {
								return itemOne.id
							})
							let parentNodes = treeData.map(itemOne => {
								if (itemOne.children && itemOne.children.length > 0) {
									return itemOne.id
								}
							})
							let resultArr = permissionArr.filter(itemOne => !parentNodes.some(itemTwo => itemTwo ==
								itemOne))
							// console.log("parentNodes", resultArr)
							res.data.permissionIdList = resultArr
							this.roleCheckedIds = resultArr
						}
						this.roleDetail = res.data
						delete this.roleDetail.permissionList
						delete this.roleDetail.permissionVOList
					}
				})
			},

			closeRoleDialog() {
				this.diaLogRoleVisible = false;
			},
			closeMessege() {
				this.messegeVisible = false;
			},
			confirmMessege() {
				deleteRoles(this.roleId).then((res) => {
					if (res.code == 0) {
						this.$message({
							message: "角色删除成功~",
							type: 'success'
						})
						this.messegeVisible = false;
						this.pageIndex = 1;
						this.rolesList()
					}
				})
			},
			async handleForm(type, scope) {
				switch (type) {
					case "add":
						this.roleCheckedIds = []
						this.roleDetail = {}
						await this.permissionTree()
						this.diaLogRoleTitle = "新增角色";
						this.diaLogRoleVisible = true;
						break;
					case "edit":
						this.roleCheckedIds = []
						this.roleDetail = {}
						await this.permissionTree()
						this.rolesDetail(scope.row.id)
						this.diaLogRoleTitle = "编辑角色";
						this.diaLogRoleVisible = true;
						break;
					case "delete":
						this.roleId = scope.row.id;
						this.messegeTip = "删除角色";
						this.messegeContent = "确定删除此角色吗?";
						this.messegeVisible = true;
						this.messegeType = "warn";
						break;
					case "disable":
						this.messegeTip = "禁用角色";
						this.messegeContent = "确定禁用此角色吗?";
						this.messegeVisible = true;
						this.messegeType = "warn";
						break;
					case "submit":
						console.log(this.roleDetail)
						// return
						this.$refs["roleDetail"].validate(valid => {
							if (valid) {
								let roleDetail = JSON.parse(JSON.stringify(this.roleDetail))
								if (this.roleCheckedIds.length > 0) {
									// let treeData = JSON.parse(JSON.stringify(this.roleTree))
									// let filterTree = treeData.filter(itemOne => this.roleCheckedIds.some(itemTwo =>
									// 	itemTwo ==
									// 	itemOne.id))
									// if (filterTree.length > 0) {
									// 	filterTree.forEach(itemOne => {
									// 		if (itemOne.children && itemOne.children.length > 0) {
									// 			itemOne.children = itemOne.children.filter(itemTwo => this
									// 				.roleCheckedIds
									// 				.some(itemThree => itemThree == itemTwo.id))
									// 		}
									// 	})
									// }
									// roleDetail.permissionIdList = filterTree
									roleDetail.permissionIdList = this.roleCheckedIds
								} else {
									roleDetail.permissionIdList = []
								}
								if (this.diaLogRoleTitle == "新增角色") {
									addRoles(roleDetail).then(res => {
										if (res.code == 0) {
											this.$message({
												message: "角色新增成功~",
												type: 'success'
											})
											this.pageIndex = 1
											this.rolesList()
											this.diaLogRoleVisible = false;
										}
									})
								} else {
									editRoles(roleDetail.id, roleDetail).then(res => {
										if (res.code == 0) {
											this.$message({
												message: "角色编辑成功~",
												type: 'success'
											})
											this.pageIndex = 1
											this.rolesList()
											this.diaLogRoleVisible = false;
										}
									})
								}
							}
						})
						break;
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";
	@import "./common.scss";

	.roleTable {
		display: flex;
		flex-direction: column;
	}
</style>
