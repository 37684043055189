<template>
	<div class="accountTable">
		<div @click="handleForm('add')" class="setUp_add_btn">
			+添加账号
		</div>
		<div class="setUp_table">
			<div class="setUp_table_box">
				<el-table class="setUp_table_inner" :header-cell-style="headStyle" :cell-style="rowStyle"
					:data="tableData" :border="false">
					<el-table-column width="80px" :index="indexFormat" type="index" label="序号">
					</el-table-column>
					<el-table-column prop="realname" label="用户姓名">
					</el-table-column>
					<el-table-column prop="avatarUrl" label="用户头像">
						<template slot-scope="scope">
							<div class="setUp_table_avator">
								<el-image fit="cover" class="avator_inner" :src="scope.row.avatarUrl?scope.row.avatarUrl:require('@/assets/images/common/avatar.png')" :preview-src-list="[scope.row.avatarUrl?scope.row.avatarUrl:require('@/assets/images/common/avatar.png')]">
								</el-image>
								<!-- <img v-if="scope.row.avatarUrl" :src="scope.row.avatarUrl">
								<img v-else src="@/assets/images/common/avatar.png"> -->
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="roleList" label="使用角色">
						<template slot-scope="scope">
							<div v-if="scope.row.roleList&&scope.row.roleList.length > 0" class="">
								{{scope.row.roleList[0].name}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="username" label="登录账号">
					</el-table-column>
					<!-- <el-table-column prop="password" label="登录密码">
					</el-table-column> -->
					<el-table-column prop="createTime" label="创建时间">
					</el-table-column>
					<el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="setUp_table_action">
								<span @click="handleForm('edit', scope)">编辑</span>
								<span @click="handleForm('delete', scope)">删除</span>
								<span :class="{'table_action_red': scope.row.status == 'A'}"
									@click="handleForm('disable', scope)">{{scope.row.status == 'A' ? '禁用' : '开启'}}</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="setUp_table_page">
				<el-pagination @current-change="currentChange" :current-page.sync="pageIndex" :page-size="pageSize"
					layout="total, prev, pager, next" :total="totalCount">
				</el-pagination>
			</div>
		</div>
		<div class="setUp_dialog">
			<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeAccountDialog" width="579px" :title="diaLogAccountTitle"
				:visible.sync="diaLogAccountVisible">
				<div class="handle_form">
					<el-form ref="accountDetail" :rules="accountRules" :model="accountDetail" label-width="70px">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" prop="realname" label="用户姓名:">
									<input v-model="accountDetail.realname" placeholder="请输入用户姓名"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" prop="avatarUrl" label="用户头像:">
									<clickUpLoad :fileList="avatarUrlList" @beforeUpload="upLoadAvatar"
										:shapeUpLoad="'circle'" />
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" prop="roleIdList" label="使用角色:">
									<el-select v-model="accountDetail.roleIdList" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in rolesSelectList" :key="item.id" :label="item.name"
											:value="item.id">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" prop="username" label="登录账号:">
									<input :disabled="diaLogAccountTitle == '编辑账号'" v-model="accountDetail.username"
										placeholder="请输入登录账号" class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" :prop="diaLogAccountTitle == '新增账号'? 'password':''"
									label="登录密码:">
									<input v-model="accountDetail.password" placeholder="请输入登录密码"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="handle_form_btn">
						<span @click="closeAccountDialog" class="handle_form_cancel">取消</span>
						<span v-preventReClick @click="handleForm('submit')" class="handle_form_confirm">确定</span>
					</div>
				</div>
			</el-dialog>
		</div>
		<messege-box ref="messegeBox" @confirmMessege="confirmMessege" @closeMessege="closeMessege"
			:isOpen="messegeVisible" :messegeTip="messegeTip" :messegeContent="messegeContent"
			:messegeType="messegeType" />
	</div>
</template>

<script>
	import {
		getRoles,
		getAccount,
		addAccount,
		detailAccount,
		editAccount,
		deleteAccount,
	} from "@/api/setUp";
	import clickUpLoad from "@/components/clickUpLoad/index.vue";
	export default {
		components: {
			clickUpLoad,
		},
		data() {
			return {
				pageIndex: 1,
				pageSize: 10,
				totalCount: 0,
				tableData: [],
				diaLogAccountTitle: "",
				diaLogAccountVisible: false,
				accountRules: {
					realname: [{
						required: true,
						message: '请输入用户姓名',
						trigger: 'change'
					}],
					avatarUrl: [{
						required: true,
						message: '请上传用户头像',
						trigger: 'change'
					}],
					roleIdList: [{
						required: true,
						message: '请选择使用角色',
						trigger: 'blur'
					}],
					username: [{
						required: true,
						message: '请输入登录账号',
						trigger: 'change'
					}],
					password: [{
						required: true,
						message: '请输入登录密码',
						trigger: 'change'
					}],
				},
				accountId: "",
				accountDetail: {},
				avatarUrlList: [],
				rolesSelectList: [],
				messegeVisible: false,
				messegeTip: "",
				messegeContent: "",
				messegeType: "",
			}
		},
		created() {
			this.accountList()
		},
		watch: {
			diaLogAccountVisible: {
				handler(val) {
					if (!val) {
						this.$nextTick(() => {
							this.$refs.accountDetail.resetFields()
							this.$refs.accountDetail.clearValidate()
						})
						this.avatarUrlList = []
					}
				},
				deep: true
			}
		},
		methods: {
			headStyle() {
				return 'text-align:center;background: #F7F7F7;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;'
			},
			rowStyle() {
				return 'text-align:center;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #333333;'
			},
			// 序号处理
			indexFormat(index){
				const page = this.pageIndex;
				const pageSize = this.pageSize;
				let resultIndex = index + 1 + (page-1) * pageSize
				return resultIndex
			},
			// 获取角色列表
			rolesList() {
				return new Promise((resolve, reject) => {
					let params = {
						pageIndex: 1,
						pageSize: 9999
					}
					getRoles(params).then((res) => {
						if (res.code == 0) {
							this.rolesSelectList = res.data
							resolve(this.rolesSelectList)
						} else {
							reject()
						}
					})
				})
			},
			// 获取账号列表
			accountList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				}
				getAccount(params).then((res) => {
					this.tableData = res.data
					this.totalCount = res.page.totalCount * 1
				})
			},
			// 账号分页
			currentChange(val) {
				this.pageIndex = val
				this.accountList()
			},
			// 上传头像
			upLoadAvatar(props) {
				console.log("upLoadAvatar", props)
				if (props && props.fileData) {
					this.avatarUrlList = [props.fileData]
					this.accountDetail.avatarUrl = props.fileData
				}
			},
			// 获取账号详情
			accountDetailFn(id) {
				detailAccount(id).then((res) => {
					if (res.code == 0) {
						if (res.data.avatarUrl) {
							this.avatarUrlList = [res.data.avatarUrl]
						}
						if (res.data.roleList) {
							res.data.roleIdList = res.data.roleList[0].id
						}
						// res.data.username = res.data.userNum
						this.accountDetail = res.data
					}
				})
			},
			// 编辑账号
			accountEdit(id, params, message) {
				editAccount(id, params).then(res => {
					if (res.code == 0) {
						this.$message({
							message: message,
							type: 'success'
						})
						this.pageIndex = 1
						this.accountList()
					}
				})
			},
			closeAccountDialog() {
				this.diaLogAccountVisible = false;
			},
			closeMessege() {
				this.messegeVisible = false;
			},
			confirmMessege() {
				switch (this.messegeTip) {
					case "删除账号":
						deleteAccount(this.accountId).then((res) => {
							if (res.code == 0) {
								this.$message({
									message: "账号删除成功~",
									type: 'success'
								})
								this.pageIndex = 1
								this.accountList()
								this.messegeVisible = false;
							}
						})
						break;
					case "禁用账号":
						let accountDetail1 = JSON.parse(JSON.stringify(this.accountDetail))
						console.log("accountDetail1", accountDetail1)
						accountDetail1.gender = "F"
						accountDetail1.status = "L"
						accountDetail1.orgId = "0"
						accountDetail1.userNum = accountDetail1.username
						accountDetail1.roleIdList = accountDetail1.roleIdList.split(",")
						this.accountEdit(accountDetail1.id, accountDetail1, '账号禁用成功~')
						this.messegeVisible = false;
						break;
					case "解禁账号":
						let accountDetail2 = JSON.parse(JSON.stringify(this.accountDetail))
						console.log("accountDetail2", accountDetail2)
						accountDetail2.gender = "F"
						accountDetail2.status = "A"
						accountDetail2.orgId = "0"
						accountDetail2.userNum = accountDetail2.username
						accountDetail2.roleIdList = accountDetail2.roleIdList.split(",")
						this.accountEdit(accountDetail2.id, accountDetail2, '账号解禁成功~')
						this.messegeVisible = false;
						break;
				}
			},
			async handleForm(type, scope) {
				switch (type) {
					case "add":
						this.accountDetail = {}
						await this.rolesList()
						this.diaLogAccountTitle = "新增账号";
						this.diaLogAccountVisible = true;
						break;
					case "edit":
						await this.rolesList()
						this.accountDetailFn(scope.row.id)
						this.diaLogAccountTitle = "编辑账号";
						this.diaLogAccountVisible = true;
						break;
					case "delete":
						this.accountId = scope.row.id;
						this.messegeTip = "删除账号";
						this.messegeContent = "确定删除此账号吗?";
						this.messegeVisible = true;
						this.messegeType = "warn";
						break;
					case "disable":
						this.accountDetailFn(scope.row.id)
						if (scope.row.status == 'A') {
							this.messegeTip = "禁用账号";
							this.messegeContent = "确定禁用此账号吗?";
						} else if (scope.row.status == 'L') {
							this.messegeTip = "解禁账号";
							this.messegeContent = "确定解禁此账号吗?";
						}
						this.messegeVisible = true;
						this.messegeType = "warn";
						break;
					case "submit":
						this.$refs["accountDetail"].validate(valid => {
							if (valid) {
								let accountDetail = JSON.parse(JSON.stringify(this.accountDetail))
								console.log("accountDetail", accountDetail)
								accountDetail.gender = "F"
								accountDetail.status = "A"
								accountDetail.orgId = "0"
								accountDetail.userNum = accountDetail.username
								accountDetail.roleIdList = accountDetail.roleIdList.split(",")
								if (this.diaLogAccountTitle == "新增账号") {
									addAccount(accountDetail).then(res => {
										if (res.code == 0) {
											this.$message({
												message: "账号新增成功~",
												type: 'success'
											})
											this.pageIndex = 1
											this.accountList()
											this.diaLogAccountVisible = false;
										}
									})
								} else {
									this.accountEdit(accountDetail.id, accountDetail, '账号编辑成功~')
									this.diaLogAccountVisible = false;
								}
							}
						})
						break;
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";
	@import "./common.scss";

	.accountTable {
		display: flex;
		flex-direction: column;
	}
</style>
