<template>
	<div class="logTable">
		<div class="setUp_table">
			<div class="setUp_table_box">
				<el-table class="setUp_table_inner" :header-cell-style="headStyle" :cell-style="rowStyle"
					:data="tableData" :border="false">
					<el-table-column :index="indexFormat" width="80px" type="index" label="序号">
					</el-table-column>
					<el-table-column prop="userRealname" label="用户名">
					</el-table-column>
					<el-table-column prop="userId" label="用户ID">
					</el-table-column>
					<el-table-column prop="businessObj" label="业务对象">
					</el-table-column>
					<el-table-column prop="operation" label="操作事项">
					</el-table-column>
					<el-table-column prop="requestMethod" label="请求方式">
					</el-table-column>
					<el-table-column prop="requestUri" label="请求URI">
						<template slot-scope="scope">
							<div class="setUp_table_cell">
								{{scope.row.requestUri}}
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="setUp_table_page">
				<el-pagination @current-change="currentChange" :current-page.sync="pageIndex" :page-size="pageSize"
					layout="total, prev, pager, next" :total="totalCount">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		logAccount
	} from "@/api/setUp";
	export default {
		data() {
			return {
				pageIndex: 1,
				pageSize: 10,
				totalCount: 0,
				tableData: []
			}
		},
		created() {
			this.accountLog()
		},
		methods: {
			headStyle() {
				return 'text-align:center;background: #F7F7F7;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;'
			},
			rowStyle() {
				return 'text-align:center;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #333333;'
			},
			// 序号处理
			indexFormat(index){
				const page = this.pageIndex;
				const pageSize = this.pageSize;
				let resultIndex = index + 1 + (page-1) * pageSize
				return resultIndex
			},
			accountLog() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				}
				logAccount(params).then((res) => {
					this.tableData = res.data
					this.totalCount = res.page.totalCount * 1
				})
			},
			// 日志分页
			currentChange(val) {
				this.pageIndex = val
				this.accountLog()
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";
	@import "./common.scss";

	.logTable {
		display: flex;
		flex-direction: column;
	}
</style>
